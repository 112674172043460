<template>
    <div class="d-notification bg-grey-900 text-white fixed rounded-8 z-50" :class="{'show-notification': showNotification}">
        <span class="close-notification absolute right-0 top-0 m-10 cursor-pointer" @click="hideNotification">
            <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path d="M1.28033009,0.219669914 L8.705,7.644 L16.1295725,0.219669914 C16.4224657,-0.0732233047 16.8973394,-0.0732233047 17.1902327,0.219669914 C17.4831259,0.512563133 17.4831259,0.987436867 17.1902327,1.28033009 L9.766,8.705 L17.1902327,16.1295725 C17.4831259,16.4224657 17.4831259,16.8973394 17.1902327,17.1902327 C16.8973394,17.4831259 16.4224657,17.4831259 16.1295725,17.1902327 L8.705,9.766 L1.28033009,17.1902327 C0.987436867,17.4831259 0.512563133,17.4831259 0.219669914,17.1902327 C-0.0732233047,16.8973394 -0.0732233047,16.4224657 0.219669914,16.1295725 L7.644,8.705 L0.219669914,1.28033009 C-0.0732233047,0.987436867 -0.0732233047,0.512563133 0.219669914,0.219669914 C0.512563133,-0.0732233047 0.987436867,-0.0732233047 1.28033009,0.219669914 Z" fill="#FFFFFF"></path>
                </g>
            </svg>
        </span>
        <span class="font-semibold text-xl font-barlow"><slot name="title"></slot></span>
        <p class="notification-text text-sm mt-2"><slot></slot></p>
    </div>
</template>

<script>
    export default {
        name: 'DNotification',
        data() {
            return {
                showNotification: false,
            }
        },
        methods: {
            hideNotification() {
                localStorage.setItem('seen_cookie', '1')
                this.showNotification = false
            },
        },
        mounted() {
            if (localStorage.getItem('seen_cookie') !== '1') {
                // show after 1.5 seconds for page load reasons
                setTimeout(() => {
                    this.showNotification = true
                }, 1500)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .d-notification {
        right: 48px;
        bottom: 48px;
        max-width: 534px;
        padding: 34px 100px 34px 40px;
        box-shadow: 0 3px 8px 0 rgba(0,0,0,0.11);
        transform: translateY(150%);
        transition: transform 0.5s;
        @media (max-width: 992px) {
            right: 0;
            bottom: 0;
            border-radius: 0px;
            padding: 24px 65px 24px 24px;
        }
        &.show-notification {
            transform: translateY(0px);
        }
        .notification-text {
            line-height: 24px;
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .close-notification {
            &:hover {
                svg g {
                    stroke-width: 2px;
                }
            }
        }
    }
</style>
