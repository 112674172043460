<template>
    <div ref="grid" class="member-grid">
        <div ref="member" class="member relative" v-for="member in visibleMembers" :key="member.name" :class="{'is-empty': member.name === 'empty'}">
            <div v-if="member.name !== 'empty'">
                <picture>
                    <source v-if="member.images.webpSrcset" sizes="180px" :data-srcset="member.images.webpSrcset" type="image/webp">

                    <img :src="member.images.src" sizes="180px" class="lazyload" :data-srcset="member.images.srcset">
                </picture>
                <span class="absolute text-white text-center inset-x-0 font-semibold leading-none text-base">{{member.name}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'

    export default {
        name: 'DTeam',
        props: {
            members: Array,
        },

        data() {
            return {
                gridMembers: [],
                showEmpty: true,
            }
        },

        computed: {
            visibleMembers() {
                return _.filter(_.cloneDeep(this.members), (member, index) => this.shouldShow(member, index))
            },
        },

        methods: {
            shouldShow(member, index) {
                return (member.name === 'empty' && index > 0) ? this.showEmpty : true
            },
            handleResize() {
                const winWidth = window.innerWidth
                this.showEmpty = false
                if (winWidth > 1280) {
                    this.showEmpty = true
                }

                if (winWidth > 1280 && this.$refs.grid.className !== 'member-grid') {
                    this.$refs.grid.className = 'member-grid'
                }
                if (winWidth < 1280 && winWidth > 1080 && this.$refs.grid.className !== 'member-grid five-col') {
                    this.$refs.grid.className = 'member-grid five-col'
                }
                if (winWidth < 1080 && winWidth > 850 && this.$refs.grid.className !== 'member-grid four-col') {
                    this.$refs.grid.className = 'member-grid four-col'
                }
                if (winWidth < 850 && winWidth > 580 && this.$refs.grid.className !== 'member-grid three-col') {
                    this.$refs.grid.className = 'member-grid three-col'
                }
                if (winWidth < 580 && this.$refs.grid.className !== 'member-grid two-col') {
                    this.$refs.grid.className = 'member-grid two-col'
                }
            },

            loadImages() {
                _.each(this.$refs.member, (member, index) => {
                    _.delay(() => {
                        member.classList.remove('is-loading')
                    }, 100 * index)
                })
            },
        },

        mounted() {
            this.handleResize()
            window.addEventListener('resize', this.handleResize)
        },
    }
</script>

<style lang="scss" scoped>
    .member-grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 90px;
        grid-gap: 15px;
        width: 100%;
        @screen sm {
            grid-gap: 30px;
        }
        .member {
            width: 150px;
            height: 150px;
            margin: 0 auto;
            transition: opacity 0.4s;
            opacity: 1;
            @screen sm {
                width: 180px;
                height: 180px;
            }
            span {
                bottom: 20px;
            }
            &.is-empty {
                display: none;
            }
            &.is-loading {
                opacity: 0;
            }
        }

        $offset: 0;
        @for $i from 1 through 100 {
            .member:nth-child(#{$i}) {
                grid-column: 6 - (($i - 1) % 6);
                grid-row: #{(($i - 1) % 6) + $offset} / #{(($i - 1) % 6) + $offset + 2};
                @if $i % 6 == 0 {
                    $offset: $offset + 2;
                }
            }
        }
        &.five-col {
            grid-template-columns: repeat(5, 1fr);
            $offset: 0;
            @for $i from 1 through 100 {
                .member:nth-child(#{$i}) {
                    grid-column: 5 - (($i - 1) % 5);
                    grid-row: #{(($i - 1) % 5) + $offset} / #{(($i - 1) % 5) + $offset + 2};
                    @if $i % 5 == 0 {
                        $offset: $offset + 2;
                    }
                }
            }
        }

        &.four-col {
            grid-template-columns: repeat(4, 1fr);
            $offset: 0;
            @for $i from 1 through 100 {
                .member:nth-child(#{$i}) {
                    grid-column: 4 - (($i - 1) % 4);
                    grid-row: #{(($i - 1) % 4) + $offset} / #{(($i - 1) % 4) + $offset + 2};
                    @if $i % 4 == 0 {
                        $offset: $offset + 2;
                    }
                }
            }
        }
        &.three-col {
            grid-template-columns: repeat(3, 1fr);
            $offset: 0;
            @for $i from 1 through 100 {
                .member:nth-child(#{$i}) {
                    grid-column: 3 - (($i - 1) % 3);
                    grid-row: #{(($i - 1) % 3) + $offset} / #{(($i - 1) % 3) + $offset + 2};
                    @if $i % 3 == 0 {
                        $offset: $offset + 2;
                    }
                }
            }
        }
        &.two-col {
            grid-template-columns: repeat(2, 1fr);
            $offset: 0;
            @for $i from 1 through 100 {
                .member:nth-child(#{$i}) {
                    grid-column: 2 - (($i - 1) % 2);
                    grid-row: #{(($i - 1) % 2) + $offset} / #{(($i - 1) % 2) + $offset + 2};
                    @if $i % 2 == 0 {
                        $offset: $offset + 2;
                    }
                }
            }
        }
    }
</style>
