import Vue from 'vue'
import './components'
import './updates'
import './website'

import VueTippy, { TippyComponent } from 'vue-tippy'

import '@/styles/tailwind.css'
import '@/styles/base.scss'

Vue.config.productionTip = false


Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

new Vue({
    delimiters: ['${', '}'],
}).$mount('#app')
