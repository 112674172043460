/* eslint-disable */

import stickybits from 'stickybits'
import smoothscroll from 'smoothscroll-polyfill'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import $ from 'jquery'
import 'slick-carousel'

const digistormWeb = {
    init() {
        // smooth scroll polyfill for safari (used in mobile scrolling gallery block)
        smoothscroll.polyfill()

        digistormWeb.checkLanguage()
        digistormWeb.headingUnderlines()
        digistormWeb.explore()
        digistormWeb.iconsHeader()
        digistormWeb.imageScrollAnim()
        digistormWeb.magicScrollList()
        digistormWeb.magicClickList()
        digistormWeb.testimonials()
        digistormWeb.officeTimezones()
        digistormWeb.legalPage()
        digistormWeb.homepageResources()
        digistormWeb.wistaVideos()
        digistormWeb.customerStories()
        digistormWeb.lightbox()
        digistormWeb.scrollingGalleriesMobile()
        digistormWeb.webCatalogue()
        // console.log($('body'))
    },
    webCatalogue() {
      $('.web-catalogue').on('click', '.modal-trigger', function() {
        const modalId = $(this).data('modal-id');
        $(`#modal-${modalId}`).addClass('is-active');
        document.body.style.overflow = 'hidden';
      });

      $('.web-catalogue').on('click', '.modal-close, .modal-background', function() {
        $('.web-catalogue-modal').removeClass('is-active');
        document.body.style.overflow = 'initial';
      });

      function initializeSliders() {
        $('.web-catalogue-modal').each(function() {
          const nextArrow = $(this).find('.main-img-container button.slick-next');
          const prevArrow = $(this).find('.main-img-container button.slick-prev');
          const mainSlider = $(this).find('.main-img-container .slider');
          const thumbnailsSlider = $(this).find('.thumbnails .slider');
          const slideCounter = $(this).find('.slide-counter span');

          $(mainSlider).on('afterChange', function(event, slick, currentSlide) {
            slideCounter.html(currentSlide + 1);
          });

          if (!$(mainSlider).hasClass('slick-initialized')) {
            $(mainSlider).slick({
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: true,
              infinite: false,
              nextArrow: nextArrow,
              prevArrow: prevArrow,
              asNavFor: thumbnailsSlider,
            });
          }

          if (!$(thumbnailsSlider).hasClass('slick-initialized')) {
            $(thumbnailsSlider).slick({
              slidesToShow: 6,
              slidesToScroll: 1,
              arrows: false,
              focusOnSelect: true,
              variableWidth: true,
              infinite: false,
              asNavFor: mainSlider,
              responsive: [
                {
                  breakpoint: 1023,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  },
                },
              ],
            });
          }
        });
      }

      // Initialize sliders on page load
      initializeSliders();

      // Initialize sliders after AJAX requests
      document.body.addEventListener('htmx:afterSwap', function(event) {
        if (!event.detail.target.matches('#web-catalogue-ajax-container')) return;

        initializeSliders();
      });
    },
    helpers: {
        isIos() {
            return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        },
        isIphone() {
            return [
                'iPhone Simulator',
                'iPod Simulator',
                'iPhone',
                'iPod',
            ].includes(navigator.platform)
        },
    },
    checkLanguage() {
        const updatesDomains = [
            'updates.digistorm.com',
            'updates.digistorm-website.test',
            'updates.digistorm-website.ddev.site',
            'digistorm-updates-staging.digistormhosting.com.au',
            'digistorm-website.test', // TODO temporary
        ]
        if (window.location.pathname.length && window.location.pathname.startsWith('/web-catalog')) {
          return
        }
        if (updatesDomains.includes(window.location.hostname)) {
            // Redirects not needed on updates
            return
        }
        const pathComponents = window.location.pathname.slice(1).split('/')
        if (!pathComponents || !['au', 'us'].includes(pathComponents[0])) {
            const auDefault = true
            if (auDefault) {
                const isEnglish = navigator.language.toLowerCase().startsWith('en')
                const timezoneOffset = new Date().getTimezoneOffset() / 60 * -1

                // Timezones less than -2 are most likely in the Americas or Greenland
                // Timezones > 8 are Asia/AU/NZ
                let siteLocale = ['au']
                if (timezoneOffset < -2 && isEnglish) {
                    siteLocale = ['us']
                }
                window.location = `/${siteLocale.concat(pathComponents).join('/')}`
            } else {
                // Attempt to determine the users language/region for homepage redirect
                try {
                    // 1. Use timezone
                    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
                    const isAustralia = timezone.toLowerCase().startsWith('australia')
                    const isNewZealand = timezone.toLowerCase() === 'pacific/auckland'
                    if (isAustralia || isNewZealand) {
                        window.location = '/au/'
                    }
                } catch {
                    // Not available
                    // 2. Use language + timezone offset
                    const isEnglish = navigator.language.toLowerCase().startsWith('en')
                    const timezoneOffset = new Date().getTimezoneOffset() / 60 * -1

                    // Timezones less than -2 are most likely in the Americas or Greenland
                    // Timezones > 8 are Asia/AU/NZ
                    if (timezoneOffset > 8 && isEnglish) {
                        window.location = '/au/'
                    }
                }
            }
        }
    },
    headingUnderlines() {
        const headings = document.getElementsByClassName('curved-underlines')
        for (let i = 0; i < headings.length; i++) {
            const underlines = headings[i].getElementsByTagName('u')
            for (let j = 0; j < underlines.length; j++) {
                underlines[j].innerHTML += '<svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 200 20"><path d="M 0 10 q 100 -10 200 0" stroke-width="2" fill="none" stroke="currentColor" stroke-linecap="round"></path></svg>'
            }
            const strongSvg = '<svg width="39px" height="38px" viewBox="0 0 39 38" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g id="Homepage---desktop" transform="translate(-592.000000, -4628.000000)" stroke="#FFFFFF" stroke-width="1.5"><g id="CTA-block" transform="translate(0.000000, 4273.000000)"><g id="Group-7" transform="translate(611.503313, 373.799784) scale(-1, 1) rotate(-228.000000) translate(-611.503313, -373.799784) translate(598.503313, 361.299784)"><path d="M3.08476736,1.88729217 C5.51018104,3.59370602 20.0847674,14.8872922 20.0847674,14.8872922" id="Stroke-1" transform="translate(11.584767, 8.387292) rotate(3.000000) translate(-11.584767, -8.387292) "></path><path d="M12.0858053,23.0540867 C12.0858053,23.0540867 1.84780441,13.8035454 1.84780441,13.8035454" id="Stroke-3" transform="translate(6.966805, 18.428816) rotate(-21.000000) translate(-6.966805, -18.428816) "></path><path d="M23.5013781,11.8165628 C23.5013781,11.8165628 23.2731784,-0.00406275801 23.2731784,-0.00406275801 L23.5013781,11.8165628 Z" id="Stroke-5" transform="translate(23.387278, 5.906250) rotate(-20.000000) translate(-23.387278, -5.906250) "></path></g></g></g></g></svg>'
            const strongs = headings[i].getElementsByTagName('strong')
            for (let j = 0; j < strongs.length; j++) {
                strongs[j].innerHTML = strongSvg + strongs[j].innerHTML
            }
            const emSvg = '<svg width="26" height="29" viewBox="0 0 26 29" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_873_3047)"><path d="M5.69322 5.28553C5.47043 7.55838 5.24765 9.83123 5.02487 12.1041" stroke="#3E8AF6" stroke-width="0.967096" stroke-linecap="round"/><path d="M7.45323 19.2073C11.6212 17.0069 15.7892 14.8064 19.9572 12.606" stroke="#3E8AF6" stroke-width="0.967096" stroke-linecap="round"/><path d="M9.67322 25.0262C11.2816 25.222 12.8901 25.4178 14.4985 25.6136" stroke="#3E8AF6" stroke-width="0.967096" stroke-linecap="round"/></g><defs><clipPath id="clip0_873_3047"><rect width="16.1183" height="23.855" fill="white" transform="translate(0 7.34033) rotate(-27.0917)"/></clipPath></defs></svg>'
            const ems = headings[i].getElementsByTagName('em')
            for (let j = 0; j < ems.length; j++) {
                ems[j].innerHTML += emSvg
            }
        }
    },
    explore() {
        // EXPLORE
        const switchables = document.getElementsByClassName('explore no-general-image')
        for (let switchIndex = 0; switchIndex < switchables.length; switchIndex++) {
            const buttons = switchables[switchIndex].getElementsByClassName('dest-button')
            for (let b = 0; b < buttons.length; b++) {
                buttons[b].addEventListener('mouseout', (e) => {
                    e.currentTarget.parentNode.parentNode.classList.remove('hovering-other')
                })
                buttons[b].addEventListener('mouseover', (e) => {
                    const target = e.currentTarget.parentNode
                    if (!target.classList.contains('active')) {
                        target.parentNode.classList.add('hovering-other')
                    }
                })
                buttons[b].addEventListener('click', (e) => {
                    const destination = e.currentTarget.parentNode
                    const destinationsWrapper = destination.parentNode
                    const destinations = destinationsWrapper.getElementsByClassName('destination')
                    let targetIndex = -1
                    for (let j = 0; j < destinations.length; j++) {
                        destinations[j].classList.remove('active')
                        if (destinations[j] === destination) {
                            targetIndex = j
                        }
                    }
                    destination.classList.add('active')
                    const background = destinationsWrapper.getElementsByClassName('floating-background')[0]
                    // position should be calculated based on position
                    background.style.top = `${targetIndex * 80}px`

                    const targetEl = destination

                    if (window.innerWidth < 768) {
                        // mobile scroll
                        digistormWeb.scroll.toElement(targetEl, 'bottom')
                    }
                })
            }
        }
    },
    scroll: {
        timeout: null,
        start: null,
        initial: null,
        final: null,
        element: null,
        position: null,
        running: false,
        duration: 300,
        step(timestamp) {
            digistormWeb.scroll.running = true
            if (digistormWeb.scroll.start === null) {
                digistormWeb.scroll.start = timestamp
            }
            const elapsed = timestamp - digistormWeb.scroll.start
            let final = digistormWeb.scroll.final
            if (digistormWeb.scroll.element) {
                const targetRect = digistormWeb.scroll.element.getBoundingClientRect()
                if (digistormWeb.scroll.position === 'bottom') {
                    // Align bottom edge of element to bottom edge of screen
                    final = window.pageYOffset + targetRect.top + targetRect.height - window.innerHeight
                } else {
                    // Align top edge of element to top edge of screen
                    final = window.pageYOffset + targetRect.top
                }
            }
            // currently only linear, change this calculation for animation curves
            const distance = (final - digistormWeb.scroll.initial) * (elapsed / digistormWeb.scroll.duration)
            if (elapsed < digistormWeb.scroll.duration) {
                window.scroll(0, digistormWeb.scroll.initial + distance)
                window.requestAnimationFrame(digistormWeb.scroll.step)
            } else {
                // finished animation, ensure at final position
                window.scroll(0, final)
                digistormWeb.scroll.running = false
            }
        },
        toElement(element, position) {
            digistormWeb.scroll.initial = window.pageYOffset
            digistormWeb.scroll.element = element
            digistormWeb.scroll.start = null
            digistormWeb.scroll.final = null
            digistormWeb.scroll.position = position
            if (!digistormWeb.scroll.running) {
                window.requestAnimationFrame(digistormWeb.scroll.step)
            }
        },
        to(position) {
            digistormWeb.scroll.initial = window.pageYOffset
            digistormWeb.scroll.element = null
            digistormWeb.scroll.start = null
            digistormWeb.scroll.final = position
            if (!digistormWeb.scroll.running) {
                window.requestAnimationFrame(digistormWeb.scroll.step)
            }
        },
        delay(callback, delay) {
            // delayed scroll request, clear to prevent multiple scroll targets
            clearTimeout(digistormWeb.scroll.timeout)
            digistormWeb.scroll.timeout = setTimeout(() => {
                callback()
            }, delay)
        },
    },
    iconsHeader() {
        function transformIcon(id) {
            const icon = document.getElementById(id)
            const translateX = Math.floor(Math.random() * 100)
            const translateY = Math.floor(Math.random() * 100)
            const scale = Math.random() / 4 + 0.75
            const nextUpdate = Math.random() * 3000
            icon.style.transform = `translateX(${translateX}%) translateY(${translateY}%) scale(${scale})`
        }
        const icons = document.getElementsByClassName('header-icon')
        for (let i = 0; i < icons.length; i++) {
            transformIcon(icons[i].id)
        }
    },
    imageScrollAnim() {
        function checkImageItems() {
            const items = document.getElementsByClassName('text-and-image scroll-animation')
            for (let i = 0; i < items.length; i++) {
                const item = items[i]
                const rect = item.getBoundingClientRect()
                const center = window.innerHeight / 2

                item.classList.remove('start')

                // Active item (scroll position)
                if ((center > rect.top || i === 0) && (center < rect.bottom || i === items.length - 1)) {
                    item.classList.add('active')
                    item.classList.remove('above')
                    item.classList.remove('below')
                } else {
                    item.classList.remove('active')
                    if (center < rect.top) {
                        item.classList.add('above')
                    } else {
                        item.classList.add('below')
                    }
                }

                if ((i === 0) && (center < rect.top + (rect.height / 2))) {
                    // first item should float only when scrolled below center
                    item.classList.remove('float')
                } else if ((i === items.length - 1) && (center > rect.bottom - (rect.height / 2))) {
                    // last item should only float when scrolled above center
                    item.classList.remove('float')
                } else {
                    // all other items should float always
                    item.classList.add('float')
                }
            }
        }

        checkImageItems()

        window.addEventListener('scroll', (e) => {
            checkImageItems()
        })
    },
    magicScrollList() {
        function checkMagicItems() {
            const lists = document.getElementsByClassName('magic-list type-scroll')
            for (let listNo = 0; listNo < lists.length; listNo++) {
                const items = lists[listNo].getElementsByClassName('magic-item')
                for (let i = 0; i < items.length; i++) {
                    const item = items[i]
                    const rect = item.getBoundingClientRect()
                    const center = window.innerHeight / 2

                    // Active item (scroll position)
                    if ((center > rect.top || i === 0) && (center < rect.bottom || i === items.length - 1)) {
                        item.classList.add('active')
                    } else {
                        item.classList.remove('active')
                    }

                    if ((i === 0) && (center < rect.top + (rect.height / 2))) {
                        // first item should float only when scrolled below center
                        item.classList.remove('float')
                    } else if ((i === items.length - 1) && (center > rect.bottom - (rect.height / 2))) {
                        // last item should only float when scrolled above center
                        item.classList.remove('float')
                    } else {
                        // all other items should float always
                        item.classList.add('float')
                    }
                }
            }
        }

        window.addEventListener('scroll', (e) => {
            checkMagicItems()
        })
    },
    magicClickList() {
        let timeout = null
        function activateItem(item) {
            const magics = item.parentNode.getElementsByClassName('magic-item')
            let thisIndex = -1
            for (let j = 0; j < magics.length; j++) {
                magics[j].classList.remove('active')
                if (magics[j] === item) {
                    thisIndex = j
                }
            }
            item.classList.add('active')

            // Update timer
            clearTimeout(timeout)
            let nextSibling = magics[0]
            if (thisIndex < magics.length - 1) {
                nextSibling = magics[thisIndex + 1]
            }
            timeout = setTimeout(() => {
                activateItem(nextSibling)
            }, 5000)
        }

        const lists = document.getElementsByClassName('magic-list type-horizontal')
        for (let listIndex = 0; listIndex < lists.length; listIndex++) {
            const items = lists[listIndex].getElementsByClassName('magic-item')
            for (let i = 0; i < items.length; i++) {
                items[i].classList.add(`item-${i}`)
                items[i].addEventListener('click', (e) => {
                    activateItem(e.currentTarget)
                })
            }
            activateItem(items[0])
        }
    },
    testimonialsPaused: false,
    testimonials() {
        let timeout = null
        function activateItem(item) {
            if (digistormWeb.testimonialsPaused) {
                timeout = setTimeout(() => {
                    activateItem(item)
                }, 5000)
                return
            }
            const slides = item.parentNode.parentNode.getElementsByClassName('testimonial')
            const controls = item.parentNode.parentNode.getElementsByClassName('control')
            let thisIndex = -1

            for (let j = 0; j < controls.length; j++) {
                controls[j].classList.remove('active')
                if (controls[j] === item) {
                    thisIndex = j
                }
            }
            item.classList.add('active')
            for (let j = 0; j < slides.length; j++) {
                if (j === thisIndex) {
                    slides[j].classList.add('active')
                } else {
                    slides[j].classList.remove('active')
                }
            }

            // Update timer
            clearTimeout(timeout)
            let nextSibling = controls[0]
            if (thisIndex < controls.length - 1) {
                nextSibling = controls[thisIndex + 1]
            }
            timeout = setTimeout(() => {
                activateItem(nextSibling)
            }, 5000)
        }

        const lists = document.getElementsByClassName('testimonial-controls')
        for (let listIndex = 0; listIndex < lists.length; listIndex++) {
            const items = lists[listIndex].getElementsByClassName('control')
            for (let i = 0; i < items.length; i++) {
                items[i].addEventListener('click', (e) => {
                    activateItem(e.currentTarget)
                })
            }
            activateItem(items[0])
        }
    },
    officeTimezones() {
        if (document.getElementsByClassName('matrix-contactPage').length) {
            // miami
            const miamiTime = (new Date()).toLocaleTimeString('en-US', { timeZone: 'Australia/Brisbane', hour: '2-digit', minute: '2-digit' })
            document.getElementsByClassName('current-time-miami')[0].innerHTML = miamiTime
            // denver
            const denverTime = (new Date()).toLocaleTimeString('en-US', { timeZone: 'America/Denver', hour: '2-digit', minute: '2-digit' })
            document.getElementsByClassName('current-time-denver')[0].innerHTML = denverTime
            // run every 15 sec
            setTimeout(() => {
                this.officeTimezones()
            }, 15000)
        }
    },
    legalPage() {
        // nav toggle
        let legalNav = document.getElementsByClassName('legal-page-mobile-nav')
        if (legalNav.length) {
            legalNav = legalNav[0]
            const legalNavTitle = document.getElementsByClassName('legal-page-title')[0]
            legalNavTitle.addEventListener('click', (e) => {
                // only toggle if on mobile
                if (!legalNav.classList.contains('active') && window.innerWidth <= 992) {
                    legalNav.classList.add('active')
                    legalNavTitle.classList.add('active')
                } else {
                    legalNav.classList.remove('active')
                    legalNavTitle.classList.remove('active')
                }
            })
        }
        // wrap tables on legal pages in a div
        const tables = document.querySelectorAll('.legal-matrix-richText table')
        for (let i = 0; i < tables.length; i++) {
            const table = tables[i]
            const wrapper = document.createElement('div')
            wrapper.classList.add('table-wrapper')
            table.parentNode.insertBefore(wrapper, table)
            wrapper.appendChild(table)
        }
        function getCssValue(elementSelector, property) {
            const style = window.getComputedStyle(document.querySelector(elementSelector))
            const value = style.getPropertyValue(property)
            return value
        }
        // Sticky Nav Component from https://gist.github.com/javierarques/36d3cd821c5a36acd352c11f88bbf2f4
        const Sticky = (function () {
            const classFixed = 'is-fixed'
            const classAnchored = 'is-anchored'
            const Sticky = {
                element: null,
                elementParent: null,
                footerElement: document.querySelector('.footer'),
                position: 0,
                addEvents() {
                    window.addEventListener('scroll', this.onScroll.bind(this))
                    window.addEventListener('resize', this.onResize.bind(this))
                },
                init(element) {
                    this.element = element
                    this.elementParent = element.parentNode
                    this.addEvents()
                    this.calcAndSetPosition()
                    this.onScroll()
                },
                aboveScroll() {
                    return this.position < window.scrollY
                },
                onScroll(event) {
                    // get position of sidebar parent
                    const elementParentPosition = this.elementParent.getBoundingClientRect().bottom
                    // sticky sidebar is touching the top of the screen when fixed, so we just need to check this against it's height
                    if (elementParentPosition <= this.element.offsetHeight) {
                        this.setAnchored()
                    } else if (this.aboveScroll()) {
                        this.setFixed()
                    } else {
                        this.setStatic()
                    }
                },
                onResize(event) {
                    // ensure top position is in sync with screen
                    this.calcAndSetPosition()
                    this.onScroll()
                },
                calcAndSetPosition() {
                    // offset by 60 as this lines up with the fixed sidebar distance from the top of the screen (see CSS .is-fixed)
                    this.position = this.elementParent.offsetTop - 60
                },
                setFixed() {
                    this.element.classList.add(classFixed)
                    this.element.classList.remove(classAnchored)
                    this.element.style.bottom = 'initial'
                },
                setStatic() {
                    this.element.classList.remove(classFixed)
                    this.element.classList.remove(classAnchored)
                    this.element.style.bottom = 'initial'
                },
                setAnchored() {
                    // anchor element in line with the end of the page content (footer height + bottom content margin 🥴)
                    const marginOffset = parseInt(getCssValue('.legal-page-main', 'margin-bottom').replace('px', ''), 10)
                    this.element.classList.add(classAnchored)
                    this.element.style.bottom = `${this.footerElement.offsetHeight + marginOffset}px`
                },
            }
            return Sticky
        }())
        // Init Sticky
        const sticky = document.querySelector('.sticky')
        const contentElement = document.querySelector('.legal-page-content')
        const sidebarElement = document.querySelector('.legal-page-sidebar-inner')
        // if content isnt double the length of the sidebar, dont bother enabling sticky sidebar
        if (sticky && (sidebarElement.offsetHeight * 2) < contentElement.offsetHeight && window.screen.width >= 992) {
            Sticky.init(sticky)
        } else {
            // listen for if screen becomes larger than 992px, attempt to enable sticky element again
            window.addEventListener('resize', (event) => {
                // also ensure sticky element has not been initialised yet
                if (sticky && (sidebarElement.offsetHeight * 2) < contentElement.offsetHeight && window.screen.width >= 992 && Sticky.element === null) {
                    Sticky.init(sticky)
                }
            })
        }
    },
    homepageResources() {
        const postWrapper = document.querySelector('.posts-wrapper')
        if (postWrapper) {
            // default URL
            let url = '/api/hubspot.php?method=get-posts'
            // get the ids of any pinned hubspot posts
            const postIds = postWrapper.dataset.postIds
            if (postIds.length) {
                url = `/api/hubspot.php?method=get-posts&ids=${postIds}`
            }
            // fetch hubspot posts
            const request = new XMLHttpRequest()
            request.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    // render posts
                    digistormWeb.renderPosts(JSON.parse(request.response))
                }
            }
            request.open('GET', url, true)
            request.send()
        }
    },
    renderPosts(posts) {
        // if we have hubspot posts, render them on the page
        if (posts.length) {
            // ensure we dont display more than 3 posts
            if (posts.length > 3) {
                posts = posts.slice(0, 3)
            }
            const postWrapper = document.querySelector('.posts-wrapper')
            postWrapper.innerHTML = ''
            posts.forEach((post) => {
                const postElement = document.createElement('a')
                postElement.href = post.url
                postElement.target = '_blank'
                postElement.innerHTML = `<h4><span>${post.title}</span></h4><div class="post-summary">${post.summary}</div><div><span class="post-category">${post.feed}</span><span class="post-date">${post.published_at}</span></div>`
                postWrapper.appendChild(postElement)
            })
        } else {
            document.querySelector('.home-resources').style.display = 'none'
        }
    },
    wistaVideos() {
        if (!window._wq) {
            window._wq = []
        }
        // Setup modals that inject embed codes
        const modals = document.querySelectorAll('.wistia-video-modal')

        modals.forEach((modal) => {
            const preview = modal.closest('.video-preview')
            const content = modal.querySelector('.modal-content')
            let html = '<div class="wistia_responsive_padding" style="padding:56.25% 0 28px 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_REPLACEME videoFoam=true" style="height:100%;width:100%">&nbsp;</div></div></div>'

            // This needs to be appened to load
            const script = document.createElement('script')
            script.src = '//fast.wistia.com/assets/external/E-v1.js'

            if (content.attributes['data-embed'] || content.attributes['data-wistia-code']) {
                let code = ''
                if (content.attributes['data-wistia-code']) {
                    // Get the manually set code
                    code = content.attributes['data-wistia-code'].value
                } else {
                    // Get the code from the embed - this is dumb replace this
                    code = decodeURIComponent(content.attributes['data-embed'].value).split('.jsonp')[0].split('medias/')[1]
                }
                html = html.replace(/REPLACEME/g, code)
                preview.querySelector('.preview-video').addEventListener('click', (e) => {
                    // Pause slider and open modal
                    digistormWeb.testimonialsPaused = true
                    modal.classList.add('active')
                    // Render the embed code
                    const content = modal.querySelector('.modal-content')
                    content.innerHTML = html
                    content.append(script)
                })
                modal.querySelector('.modal-background').addEventListener('click', (e) => {
                    // Resume slider close modal and remove embed
                    digistormWeb.testimonialsPaused = false
                    e.currentTarget.closest('.wistia-video-modal').classList.remove('active')
                    content.innerHTML = ''
                })
            }
        })

        window._wq.push({
            id: '_all',
            onReady(video) {
                // Behaviours on video load
                const preview = video.container.closest('.video-preview')
                const modal = video.container.closest('.wistia-video-modal')
                const element = video.container.closest('.wistia-element')
                if (preview && modal) {
                    // Modal video, just created, play immediately
                    video.play()
                } else if (element) {
                    // Inline video, show and play on click
                    const wrapper = element.closest('.video-container')
                    wrapper.querySelector('.video-overlay').addEventListener('click', (e) => {
                        element.classList.add('active')
                        video.play()
                    })
                    wrapper.querySelector('.play-icon').addEventListener('click', (e) => {
                        element.classList.add('active')
                        video.play()
                    })
                } else {
                    // Looping video, resize and play immediately
                    const loop = video.container.closest('.looping-video')
                    const videoEl = loop.querySelector('div')
                    const placeholderRatio = 0.85
                    const newWidth = placeholderRatio / (videoEl.offsetHeight / videoEl.offsetWidth)
                    loop.style.width = `${newWidth * 100 + 1}%`
                    loop.style.left = '50%'
                    loop.style.marginLeft = `${newWidth * 100 * -0.5}%`
                    window.setTimeout(() => {
                        loop.style.opacity = 1
                    }, 1000)
                    video.play()
                }
            },
        })
    },
    customerStories() {
        if (document.querySelector('.customer-stories-entry')) {
            digistormWeb.customerStoriesEntry()
        } else if (document.querySelector('.customer-stories-listing')) {
            digistormWeb.customerStoriesListing.init()

            // handle filter active effect
            const filters = document.querySelectorAll('.cs-filter')
            const indicator = document.querySelector('.cs-filter-indicator')
            filters.forEach((filter) => {
                filter.addEventListener('mouseover', (e) => {
                    if (window.innerWidth >= 1024) {
                        indicator.style.left = `${filter.offsetLeft}px`
                        indicator.style.width = `${filter.offsetWidth}px`
                    }
                })
                filter.addEventListener('mouseout', (e) => {
                    if (window.innerWidth >= 1024) {
                        const activeFilter = document.querySelector('.cs-filter.is-active')
                        indicator.style.left = `${activeFilter.offsetLeft}px`
                        indicator.style.width = `${activeFilter.offsetWidth}px`
                    }
                })
                // separate listener for mobile to avoid weird behaviour with touch hover states
                filter.addEventListener('click', (e) => {
                    if (window.innerWidth <= 1024) {
                        indicator.style.left = `${filter.offsetLeft}px`
                        indicator.style.width = `${filter.offsetWidth}px`

                        const filterParent = filter.parentNode.parentNode
                        const position = filter.getBoundingClientRect()

                        // checking if filter is fully visible
                        if (position.left >= 0 && position.right <= window.innerWidth) {
                            // is visible
                        } else {
                            // not fully visible
                            filterParent.scrollLeft = filter.offsetLeft
                        }
                    }
                })
            })
            window.addEventListener('resize', (e) => {
                setTimeout(() => {
                    const activeFilter = document.querySelector('.cs-filter.is-active')
                    indicator.style.left = `${activeFilter.offsetLeft}px`
                    indicator.style.width = `${activeFilter.offsetWidth}px`
                }, 100)
            })
        }
        // init slider story on products pages
        const csBlocks = document.querySelectorAll('.customer-stories-block')
        if (csBlocks.length) {
            digistormWeb.customerStoriesSliders(csBlocks)
        }
    },
    customerStoriesListing: {
        entriesPerPage: 6,
        currentCategoryId: null,
        initialEntries: null,
        filteredEntries: null,
        loadedEntries: null,
        elements: {
            entriesContainer: null,
            loadMoreButton: null,
            filterButtons: null,
        },
        init() {
            this.elements.parentElement = document.querySelector('.customer-stories-list')
            this.elements.entriesContainer = document.querySelector('.customer-stories-container')
            this.elements.loadMoreButton = document.querySelector('.load-more-stories')
            this.elements.filterButtons = document.querySelectorAll('.cs-filter')
            this.fetch()
            // load more listener
            this.elements.loadMoreButton.addEventListener('click', (e) => {
                digistormWeb.customerStoriesListing.loadMore()
            })
            // filter listener
            this.elements.filterButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    this.elements.entriesContainer.classList.add('fade-out')
                    // reset active classes
                    this.elements.filterButtons.forEach((el) => {
                        el.classList.remove('is-active')
                    })
                    e.currentTarget.classList.add('is-active')
                    setTimeout((el) => {
                        this.filterEntries(button.dataset.categoryId)
                    }, 300)
                })
            })
        },
        fetch() {
            // set endpoint
            const uriComponents = window.location.pathname.slice(1).split('/')
            const lang = uriComponents.length ? uriComponents[0] : 'au' // default to au
            const url = `/${lang}/customerStories/stories.json`
            // fetch entries
            const request = new XMLHttpRequest()
            request.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    // update state once entries are loaded
                    digistormWeb.customerStoriesListing.initialState(JSON.parse(request.response))
                }
            }
            request.open('GET', url, true)
            request.send()
        },
        initialState(entries) {
            this.initialEntries = entries
            this.filterEntries(null)
            this.elements.parentElement.classList.remove('is-loading')
        },
        filterEntries(categoryId) {
            if (categoryId) {
                this.currentCategoryId = parseInt(categoryId, 10)
                this.filteredEntries = this.getEntriesByCategory(this.currentCategoryId)
            } else {
                this.currentCategoryId = null
                this.filteredEntries = this.getEntriesByCategory(null)
            }
            // get first set of entries and set them as "loaded"
            this.loadedEntries = this.filteredEntries.slice(0, this.entriesPerPage)
            this.elements.entriesContainer.classList.remove('fade-out')
            this.renderEntries(this.loadedEntries, true)
        },
        getEntriesByCategory(categoryId) {
            if (categoryId) {
                // filter entries by product
                const filteredEntries = []
                this.initialEntries.forEach((entry) => {
                    const productIds = []
                    entry.fields.products.forEach((product) => {
                        productIds.push(product.categoryId)
                    })
                    if (productIds.includes(categoryId)) {
                        filteredEntries.push(entry)
                    }
                })
                return filteredEntries
            }
            return this.initialEntries
        },
        loadMore() {
            // slice some more entries out of the filtered set
            const newEntries = this.filteredEntries.slice(this.loadedEntries.length, this.loadedEntries.length + this.entriesPerPage)
            this.loadedEntries = this.loadedEntries.concat(newEntries)
            this.renderEntries(newEntries, false)
        },
        getEntriesHtml(entries) {
            const entryElements = []
            entries.forEach((entry, index) => {
                const products = []
                entry.fields.products.forEach((product) => {
                    products.push(product.title)
                })
                const entryHtml = `
                    <a class="customer-story-item fade-in-${index + 1}" href="${entry.url}">
                        <div class="csi-image">
                            <div class="csi-image-bg" style="background-image: url('${entry.fields.image}');"></div>
                            <div class="csi-image-logo"><img src="${entry.fields.schoolLogo}" /></div>
                        </div>
                        <div class="csi-categories">${products.join(', ')}</div>
                        <div class="csi-title"><span>${entry.title}</span></div>
                        <div class="csi-summary">${entry.fields.summary}</div>
                    </a>
                `
                entryElements.push(entryHtml)
            })
            return entryElements.join('')
        },
        renderEntries(entries, clear) {
            if (clear) {
                this.elements.entriesContainer.innerHTML = ''
            }
            const entriesHtml = this.getEntriesHtml(entries)
            // append html to end of container
            this.elements.entriesContainer.insertAdjacentHTML('beforeend', entriesHtml)
            // if all entries are showing, hide button
            if (this.filteredEntries.length === this.loadedEntries.length) {
                this.elements.loadMoreButton.classList.add('hidden')
            } else {
                this.elements.loadMoreButton.classList.remove('hidden')
            }
            setTimeout(() => {
                document.querySelectorAll('.customer-story-item:not(.fade-in)').forEach((item) => {
                    item.classList.add('fade-in')
                })
            }, 100)
        },
    },
    customerStoriesEntry() {
        // enable sticky sidebar
        stickybits('.cs-entry-stats-sticky', { useFixed: true })
        // check for sticky sidebar collision with block quotes
        const sidebar = document.querySelector('.cs-entry-stats-sticky')
        window.addEventListener('DOMContentLoaded', (e) => {
            setTimeout(() => {
                digistormWeb.checkSidebarCollision(sidebar)
            }, 500)
        })
        window.addEventListener('scroll', (e) => {
            digistormWeb.checkSidebarCollision(sidebar)
        })
    },
    checkSidebarCollision(sidebar) {
        const blockquotes = document.querySelectorAll('.cs-blockquote-outer')
        let isOverlapping = false
        blockquotes.forEach((item) => {
            const sidebarPos = sidebar.getBoundingClientRect()
            const quotePos = item.getBoundingClientRect()
            // ¯\_(ツ)_/¯
            if (!(sidebarPos.right < quotePos.left || sidebarPos.left > quotePos.right || sidebarPos.bottom < quotePos.top || sidebarPos.top > quotePos.bottom)) {
                isOverlapping = true
            }
        })
        if (isOverlapping) {
            sidebar.classList.add('stats-hidden')
        } else {
            sidebar.classList.remove('stats-hidden')
        }
    },
    customerStoriesSliders(csBlocks) {
        // if i could go back in time this setHeight method would not exist
        function setHeight(elements) {
            if (elements instanceof NodeList) {
                elements.forEach((block) => {
                    const wrapper = block.querySelector('.stories-wrapper-inner')
                    const height = parseInt(block.querySelector('.story-item.active').getBoundingClientRect().height, 10)
                    wrapper.style.height = `${height}px`
                })
            } else {
                const wrapper = elements.querySelector('.stories-wrapper-inner')
                const height = parseInt(elements.querySelector('.story-item.active').getBoundingClientRect().height, 10)
                wrapper.style.height = `${height}px`
            }
        }
        function csSliderGoTo(parent, currentSlide) {
            parent.classList.add('no-click')
            parent.querySelector('.story-item.active').classList.remove('active')
            const targetIndex = currentSlide.dataset.targetSlide
            const targetSlide = parent.querySelector(`.story-item-${targetIndex}`)
            targetSlide.classList.add('active')
            setTimeout(() => {
                parent.classList.remove('no-click')
            }, 800)
            if (window.innerWidth < 992) {
                setHeight(parent)
            }
        }
        function csSliderAutoplay(block) {
            // if a slide on this block has been recently manually transitioned, skip this iteration
            if (block.dataset.wasClicked === 'true') {
                // skip this iteration and wait 3 seconds
                block.dataset.wasClicked = false
                setTimeout(() => {
                    csSliderAutoplay(block)
                }, 3000)
            } else {
                // go to next slide
                const currentSlide = block.querySelector('.story-item.active .controls-next')
                csSliderGoTo(block, currentSlide)
                // wait 6 seconds
                setTimeout(() => {
                    csSliderAutoplay(block)
                }, 6000)
            }
        }
        csBlocks.forEach((block) => {
            // could probably condense to a single call/loop
            const prevButtons = block.querySelectorAll('.controls-prev')
            const nextButtons = block.querySelectorAll('.controls-next')
            nextButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    block.dataset.wasClicked = true
                    csSliderGoTo(block, e.target)
                })
            })
            prevButtons.forEach((button) => {
                button.addEventListener('click', (e) => {
                    block.dataset.wasClicked = true
                    csSliderGoTo(block, e.target)
                })
            })
            // autotransition every 6 sec
            setTimeout(() => {
                csSliderAutoplay(block)
            }, 6000)
        })
        // these slides are absolutely positioned, so we need to make sure the parent is the correct height
        setHeight(csBlocks)
        window.addEventListener('resize', (e) => {
            setHeight(csBlocks)
        })
    },
    lightbox() {
        const bodyEl = document.querySelector('body')
        const lightboxControlsPrev = document.querySelectorAll('.scrolling-gallery-lightbox .item .prev')
        const lightboxControlsNext = document.querySelectorAll('.scrolling-gallery-lightbox .item .next')
        const lightboxBackdrops = document.querySelectorAll('.lightbox-backdrop')
        const lightboxTriggers = document.querySelectorAll('.lightbox-trigger')
        // close
        lightboxBackdrops.forEach((backdrop) => {
            backdrop.addEventListener('click', (e) => {
                backdrop.parentElement.classList.remove('active')
                backdrop.parentElement.querySelector('.item.active').classList.remove('active')
                bodyEl.classList.remove('modal-active')
            })
        })
        // open
        lightboxTriggers.forEach((trigger) => {
            trigger.addEventListener('click', (e) => {
                const lightboxTarget = document.querySelector(`.${trigger.dataset.target}`)
                lightboxTarget.classList.add('active')
                lightboxTarget.parentElement.classList.add('active')
                bodyEl.classList.add('modal-active')
            })
        })
        // scroll prev
        lightboxControlsPrev.forEach((button) => {
            button.addEventListener('click', (e) => {
                const imageSlide = button.parentNode
                imageSlide.classList.remove('active')
                // check we havent reached the start
                if (imageSlide.previousElementSibling && imageSlide.previousElementSibling.classList.contains('item')) {
                    // go to prev image
                    imageSlide.previousElementSibling.classList.add('active')
                } else {
                    // loop back around the last image
                    imageSlide.parentNode.querySelector('.item:last-child').classList.add('active')
                }
            })
        })
        // scroll next
        lightboxControlsNext.forEach((button) => {
            button.addEventListener('click', (e) => {
                const imageSlide = button.parentNode
                imageSlide.classList.remove('active')
                // check we havent reached the end
                if (imageSlide.nextElementSibling && imageSlide.nextElementSibling.classList.contains('item')) {
                    // go to next image
                    imageSlide.nextElementSibling.classList.add('active')
                } else {
                    console.log(imageSlide.parentNode.querySelectorAll('.item')[0])
                    // loop back around the first image
                    imageSlide.parentNode.querySelectorAll('.item')[0].classList.add('active')
                }
            })
        })
    },
    scrollingGalleriesMobile() {
        const galleries = document.querySelectorAll('.scrolling-gallery')
        galleries.forEach((gallery) => {
            const rows = document.querySelectorAll('.scrolling-gallery-row')
            gallery.querySelector('.scroll-button').addEventListener('click', (e) => {
                rows.forEach((row) => {
                    const rowInner = row.querySelector('.row-inner')
                    rowInner.scroll({
                        left: rowInner.scrollLeft + 360,
                        behavior: 'smooth',
                    })
                })
            })
        })
    },

}
window.addEventListener('DOMContentLoaded', (event) => {
    digistormWeb.init()
})
