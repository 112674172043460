<template>
    <div>
        <slot v-if="!webpSupported"></slot>
        <slot name="webp" v-else></slot>
    </div>
</template>

<script>
    export default {
        name: 'DWebpImage',
        data() {
            return {
                webpSupported: true,
            }
        },


        methods: {
            canUseWebP() {
                const elem = document.createElement('canvas')

                if (elem.getContext && elem.getContext('2d')) {
                    // was able or not to get WebP representation
                    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
                }

                // very old browser like IE 8, canvas not supported
                return false
            },
        },

        created() {
            this.webpSupported = this.canUseWebP()
        },
    }
</script>
